import React from "react";
import Navbar from "../components/Navbar/Navbar";
import WasHelpFull from "../components/WasThisHelpful/WasHelpFull";
import ResultSearchDetailBox from "../components/SearchDetail/ResultSearchDetailBox";

const SearchResultDetailPage = () => {
  // main search result detail page where all details of items will be displayed
  return (
    <div className="bg-[#FAFAFA]">
      <Navbar />

      <WasHelpFull />

      <ResultSearchDetailBox />
    </div>
  );
};

export default SearchResultDetailPage;
